import { Route } from '@angular/router';
import { AdminRoute } from './types/admin-route';

export const ADMIN_ROUTES: Route[] = [
    {
        path: AdminRoute.Landing,
        pathMatch: 'full',
        loadComponent: () => import('../@page/admin/landing/landing.page')
    },
    {
        path: AdminRoute.Videos,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/video/list/video-list.page')
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/video/manage/video-manage.page')
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/video/manage/video-manage.page')
            },
            {
                path: ':id/sync/:part/:language',
                loadComponent: () => import('../@page/admin/video/synchronization/video-synchronization.page')
            }
        ]
    },
    {
        path: AdminRoute.Events,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/event/list/event-list.page')
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/event/manage/event-manage.page')
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/event/manage/event-manage.page')
            }
        ]
    },
    {
        path: AdminRoute.Authors,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/author/list/author-list.page')
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/author/manage/author-manage.page')
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/author/manage/author-manage.page'),
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        loadComponent: () => import('../@page/admin/author/manage/video-list.page')
                    }
                ]
            }
        ]
    },
    {
        path: AdminRoute.Categories,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/category/list/category-list.page')
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/category/manage/category-manage.page')
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/category/manage/category-manage.page')
            }
        ]
    },
    {
        path: AdminRoute.Playlists,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/playlist/list/playlist-list.page')
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/playlist/manage/playlist-manage.page')
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/playlist/manage/playlist-manage.page')
            }
        ]
    },
    {
        path: AdminRoute.Users,
        data: {
            breadcrumb: 'Users'
        },
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/user/list/user-list.page')
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/user/manage/user-manage.page')
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/user/manage/user-manage.page')
            }
        ]
    },
    {
        path: AdminRoute.Organizations,
        data: { breadcrumb: 'Organizations' },
        loadChildren: () =>
            Promise.resolve([
                {
                    path: '',
                    pathMatch: 'full',
                    loadComponent: () => import('../@page/admin/organization/list/organization-list.page')
                },
                {
                    path: 'new',
                    loadComponent: () => import('../@page/admin/organization/manage/organization-manage.page')
                },
                {
                    path: ':id',
                    loadComponent: () => import('../@page/admin/organization/manage/organization-manage.page'),
                    children: [
                        {
                            path: '',
                            pathMatch: 'full',
                            loadComponent: () => import('../@ui/admin/events-list-table/events-list.component')
                        }
                    ]
                }
            ])
    },
    {
        path: AdminRoute.Projects,
        data: { breadcrumb: 'Projects' },
        loadChildren: () =>
            Promise.resolve([
                {
                    path: '',
                    pathMatch: 'full',
                    loadComponent: () =>
                        import('../@page/admin/projects/list/project-list.page').then((m) => m.ProjectListPage)
                },
                {
                    path: 'new',
                    loadComponent: () =>
                        import('../@page/admin/projects/manage/project-manage.page').then((m) => m.ProjectManagePage)
                },
                {
                    path: ':id',
                    loadComponent: () =>
                        import('../@page/admin/projects/manage/project-manage.page').then((m) => m.ProjectManagePage)
                }
            ])
    },
    {
        path: AdminRoute.Incoming,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/incoming/list/incoming.page')
            }
        ]
    },
    {
        path: '**',
        redirectTo: AdminRoute.Landing
    }
];
