import { Component, computed, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Breadcrumb as BreadcrumbComponent } from 'primeng/breadcrumb';

export interface Breadcrumb {
    label: string;
    segment: string;
}

@Component({
    selector: 'app-breadcrumbs',
    imports: [
        BreadcrumbComponent,
        RouterLink
    ],
    styles: `
        :host {
            display: block;
        }

        ::ng-deep nav.p-breadcrumb.p-component {
            padding: 0.25rem 0;
        }

        p-breadcrumb {
            width: 100%;
        }

        a.p-breadcrumb-item-link {
            font-weight: 600;
            font-size: 1.1rem;
            color: #000000;
        }
    `,
    template: `
        <p-breadcrumb [model]="breadcrumbs()">
            <ng-template #item let-item>
                <a class="p-breadcrumb-item-link" [routerLink]="item.url">
                    {{ item.label }}
                </a>
            </ng-template>
        </p-breadcrumb>
    `,
})
export class BreadcrumbsComponent {
    public items = input.required<Breadcrumb[]>();
    protected breadcrumbs = computed(() => this.toItems(this.items()));

    private toItems(breadcrumbs: Breadcrumb[]): { label: string, url: string }[] {
        return breadcrumbs.map(({ label }, i) => ({
            label,
            url: `/admin/` + breadcrumbs.slice(0, i + 1).map(({ segment }) => segment).join('/')
        }));
    }
}
