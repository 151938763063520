import { ChangeDetectionStrategy, Component, signal, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SwiperOptions } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { ImageDirective } from '../../../../../../@common/DOM/image.directive';
import { Category } from '../../../../../../@generated';
import { AppRoute } from '../../../../../../routing/types/app-route';

@Component({
    selector: 'app-categories',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        SwiperModule,
        RouterLink,
        FaIconComponent,
        ImageDirective
    ],
    styles: `
        @use "variables" as *;

        :host {
            display: block;
            width: 100%;
            min-height: 175px;
            --slide-size: 150px;
            padding-bottom: 1rem;

            @media screen and (max-width: 900px) {
                --slide-size: 120px;
            }

            @media screen and (max-width: 600px) {
                min-height: 135px;
                --slide-size: 100px;
            }

            .slide {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: $black;
                transition: 0.05s;

                @media screen and (min-width: 600px) {
                    &:hover {
                        font-weight: bold;

                        img {
                            transform: scale(1.1);
                            transition: 0.6s;
                        }
                    }
                }
            }

            img {
                max-height: var(--slide-size);
                height: var(--slide-size);
                transform: scale(1);
                transition: 0.3s;
                aspect-ratio: 1;
            }
        }

        .hide {
            opacity: 0;
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            height: fit-content;
            z-index: 1000;
            cursor: pointer;
            font-size: 2rem;
            color: #6b7280;
            top: 50%;

            &:hover {
                color: $black;
            }
        }

        .swiper-button-prev {
            left: 0;
            border: none !important;
        }

        .swiper-button-next {
            right: 0;
            border: none !important;
        }
    `,
    template: `
        <div class="swiper">
            <swiper [config]="config" #swiper [class.hide]="!show()" (init)="show.set(true)">
                @for (category of categories; track category.id; let i = $index) {
                    <ng-template swiperSlide>
                        <a class="slide" [title]="category.name" [routerLink]="[AppRoutes.Categories, category.id]">
                            <img [src]="category.image" alt="{{ category.name }} image"
                                 [attr.loading]="i < 7 ? 'eager' : 'lazy'"/>
                            <p class="text-center">{{ category.name }}</p>
                        </a>
                    </ng-template>
                }
            </swiper>
            <button class="swiper-button-prev" (click)="slidePrev()">
                <fa-icon [icon]="faArrowLeft"/>
            </button>
            <button class="swiper-button-next" (click)="slideNext()">
                <fa-icon [icon]="faArrowRight"/>
            </button>
        </div>
    `
})
export class CategoriesComponent {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
    public AppRoutes = AppRoute;
    public faArrowRight = faArrowRight;
    public faArrowLeft = faArrowLeft;
    public show = signal(false);
    public categories: (Category & { image: string }) [] = [
        {
            id: 13,
            name: 'Computer Science',
            image: '/assets/images/categories/computer_science.png',
            total_videos: 2051,
            total_views: 0,
            videos: []
        },
        {
            id: 633,
            name: 'Data Science',
            image: '/assets/images/categories/data_science.png',
            total_videos: 1467,
            total_views: 0,
            videos: []
        },
        {
            id: 2,
            name: 'Business',
            image: '/assets/images/categories/business.png',
            total_videos: 480,
            total_views: 0,
            videos: []
        },
        {
            id: 60,
            name: 'Medicine',
            image: '/assets/images/categories/medicine.png',
            total_videos: 482,
            total_views: 0,
            videos: []
        },
        {
            id: 83,
            name: 'Biology',
            image: '/assets/images/categories/biology.png',
            total_videos: 479,
            total_views: 0,
            videos: []
        },
        {
            id: 58,
            name: 'Physics',
            image: '/assets/images/categories/physics.png',
            total_videos: 433,
            total_views: 0,
            videos: []
        },
        {
            id: 80,
            name: 'Architecture',
            image: '/assets/images/categories/architecture.png',
            total_videos: 430,
            total_views: 0,
            videos: []
        },
        {
            id: 23,
            name: 'Mathematics',
            image: '/assets/images/categories/mathematics.png',
            total_videos: 430,
            total_views: 0,
            videos: []
        },
        {
            id: 59,
            name: 'Chemistry',
            image: '/assets/images/categories/chemistry.png',
            total_videos: 387,
            total_views: 0,
            videos: []
        },
        {
            id: 171,
            name: 'Humanities',
            image: '/assets/images/categories/humanities.png',
            total_videos: 341,
            total_views: 0,
            videos: []
        },
        {
            id: 611,
            name: 'Social Sciences',
            image: '/assets/images/categories/social_sciences.png',
            total_videos: 324,
            total_views: 0,
            videos: []
        },
        {
            id: 424,
            name: 'Arts',
            image: '/assets/images/categories/arts.png',
            total_videos: 298,
            total_views: 0,
            videos: []
        },
        {
            id: 10,
            name: 'Science',
            image: '/assets/images/categories/science.png',
            total_videos: 266,
            total_views: 0,
            videos: []
        },
        {
            id: 304,
            name: 'Earth Sciences',
            image: '/assets/images/categories/earth_sciences.png',
            total_videos: 127,
            total_views: 0,
            videos: []
        },
        {
            id: 353,
            name: 'Astronomy',
            image: '/assets/images/categories/astronomy.png',
            total_videos: 74,
            total_views: 0,
            videos: []
        },
        {
            id: 3,
            name: 'Computers',
            image: '/assets/images/categories/computers.png',
            total_videos: 72,
            total_views: 0,
            videos: []
        },
        {
            id: 396,
            name: 'Life Sciences',
            image: '/assets/images/categories/life_sciences.png',
            total_videos: 11,
            total_views: 0,
            videos: []
        }
    ];

    public config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 45,
        lazy: true,
        breakpoints: {
            320: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            450: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            600: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
            720: {
                slidesPerView: 6,
                spaceBetween: 20,
            },
            900: {
                slidesPerView: 7,
                spaceBetween: 20,
            },
            1120: {
                slidesPerView: 8,
                spaceBetween: 20,
            },
        },
    };

    slideNext() {
        this.swiper?.swiperRef.slideNext(100);
    }

    slidePrev() {
        this.swiper?.swiperRef.slidePrev(100);
    }

}
