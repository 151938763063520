<header class="header white-background">
    <style>
        .btn.border-radius-small.d-flex {
            justify-content: space-between;
            align-items: center;
            /* Ensures vertical alignment */
            font-size: 0.75rem;
            font-weight: 700;
            padding: 0.3125rem;
            background-color: #F1F1F1;
            border: 0.5px solid #000;
        }

        .btn.border-radius-small.d-flex span {
            margin: 0 0.1rem;
        }

        .btn.border-radius-small.d-flex span:first-child {
            margin-left: 0;
        }

        .arrow-icon {
            display: inline-flex;
            align-items: center;
            /* Vertically align the arrow icon */
            justify-content: center;
        }

        .arrow-icon.ms-2 {
            margin-right: 0;
            /* Removed margin on the right side of the arrow */
        }

        svg {
            transform: rotate(-180deg);
            /* Keeps the arrow rotated */
            width: 0.765625rem;
            height: 0.765625rem;
            /* Ensures the arrow size is consistent */
        }
    </style>
    <div class="header-content container" [class.full-width]="onAdminRoute">
        <div class="logo">
            <a [routerLink]="AppRoutes.Home">
                <picture>
                    <source srcset="/assets/vln_tiny.svg" media="(min-width: 851px)" />
                    <img src="/assets/vln_tiny.svg" alt="logo" loading="eager" />
                </picture>
            </a>
        </div>
        <form class="search" (submit)="onSubmit()" *ngIf="!onAdminRoute">
            <fa-icon [icon]="faSearch" (click)="onSubmit()"></fa-icon>
            <input #searchBar type="search" placeholder="Search" name="text" />
        </form>   
        <div class="buttons-parent menu" (click)="isMenuOpen.set(false)" [class.hide]="!isBrowser">
            @if (!isAuthenticated()) {
            <div class="buttons not-logged-in" [class.open]="isMenuOpen()">
                <a routerLink="/{{AppRoutes.Register}}" class="btn btn-trans" data-test="register-link">
                    Sign up
                </a>
                <a routerLink="/{{ AppRoutes.Login}}" class="btn btn-primary border-radius-small"
                    data-test="login-link">
                    Sign in
                </a>
            </div>
            } @else {
            <div class="buttons logged-in" [class.open]="isMenuOpen()">
                @if (userProfile(); as profile) {
                <div>
                    <button class="btn border-radius-small d-flex" (click)="toggleProfileMenu()"
                        data-test="profile-button">
                        <span class="username">{{ profile.username }}</span>
                        <span class="arrow-icon ms-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M12 4v16" stroke="#F63737" stroke-width="2" />
                                <path d="M12 4l-6 6" stroke="#F63737" stroke-width="2" />
                                <path d="M12 4l6 6" stroke="#F63737" stroke-width="2" />
                            </svg>
                        </span>
                    </button>
                    @if (isSignedInMenuOpen()) {
                    <div class="overlay">
                        @if (isAdmin()) {
                        <a routerLink="/{{AdminRoute.Admin}}" class="btn" data-test="admin-link">
                            ADMIN
                        </a>
                        }
                        <a routerLink="/{{AppRoutes.Profile}}" class="btn" data-test="profile-link">
                            USER VIEW
                        </a>
                        <a (click)="onLogout()" class="btn" data-test="logout-link">
                            SIGN OUT
                        </a>
                    </div>
                    }
                </div>
                }
            </div>
            }
        </div>
        @if (!isAuthenticated()) {
        <div class="burger" [class.closed]="isMenuOpen()" (click)="isMenuOpen.set(!isMenuOpen())">
            <div></div>
            <div></div>
            <div></div>
        </div>
        }
    </div>
</header>