import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { AppRoute } from 'src/app/routing/types/app-route';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { User } from '../../../../../@generated';

@Component({
    selector: 'app-profile-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ ImageDirective ],
    styles: `
        @use "variables" as *;

        :host {
            height: 225px;
            position: relative;
        }

        .opacity {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.25;
        }

        .background-banner {
            display: flex;
            align-items: flex-end;
            height: 100%;
            background-image: linear-gradient(to bottom, #ff0035, #ad0024);
        }

        .container {
            display: flex;
            align-items: center;
            transform: translateY(3rem);
        }

        img {
            object-fit: cover;
            width: 11rem;
            height: 11rem;

            @media only screen and (max-width: 550px) {
                width: 9rem;
                height: 9rem;
            }

            @media only screen and (max-width: 450px) {
                width: 7.5rem;
                height: 7.5rem;
            }
        }

        h1 {
            color: $white;
            padding-bottom: 1rem;
            font-size: 3rem;

            @media only screen and (max-width: 700px) {
                font-size: 3rem;
            }

            @media only screen and (max-width: 500px) {
                font-size: 2rem;
            }

            @media only screen and (max-width: 400px) {
                font-size: 1.5rem;
            }
        }
    `,
    template: `
        <div class="opacity"></div>
        <div class="background-banner">
            <div class="container">
                <img src="./assets/character.png" alt="profile-image" loading="eager"/>
                <h1>{{ profile().first_name }} {{ profile().last_name }}</h1>
            </div>
        </div>
    `
})
export class ProfileHeaderComponent {
    public profile = input.required<User>();
    public AppRoutes = AppRoute;
}
